'use strict';

var addressHelpers = require('base/checkout/address');
var shippingHelpers = require('./shipping');
var billingHelpers = require('./billing');
var summaryHelpers = require('./summary');
var formHelpers = require('base/checkout/formErrors');
var clientSideValidation = require('../components/clientSideValidation');
var scrollAnimate = require('base/components/scrollAnimate');
const { isEmpty } = require('lodash');

/**
 * Create the jQuery Checkout Plugin.
 *
 * This jQuery plugin will be registered on the dom element in checkout.isml with the
 * id of "checkout-main".
 *
 * The checkout plugin will handle the different state the user interface is in as the user
 * progresses through the varying forms such as shipping and payment.
 *
 * Billing info and payment info are used a bit synonymously in this code.
 *
 */
(function ($) {
    $.fn.checkout = function () {
        // eslint-disable-line
        var plugin = this;

        //
        // Collect form data from user input
        //
        var formData = {
            // Shipping Address
            shipping: {},

            // Billing Address
            billing: {},

            // Payment
            payment: {},

            // Gift Codes
            giftCode: {}
        };

        //
        // The different states/stages of checkout
        //
        var checkoutStages = ['shipping', 'payment'];

        /**
         * Updates the URL to determine stage
         * @param {number} currentStage - The current stage the user is currently on in the checkout
         */
        function updateUrl(currentStage) {
            if (currentStage && checkoutStages[currentStage] == undefined) {
                members.gotoStage('shipping');
                return;
            }
            history.pushState(
                checkoutStages[currentStage],
                document.title,
                location.pathname + '?stage=' + checkoutStages[currentStage] + '#' + checkoutStages[currentStage]
            );

            if (checkoutStages[currentStage] == 'shipping') {
                $('#sms-text').show();
                if ($('.line-item-quantity')) {
                    $('.line-item-quantity').removeClass('d-none');
                    $('.line-item-quantity-display').addClass('d-none');
                }
            } else {
                $('#sms-text').hide();
                if ($('.line-item-quantity')) {
                    $('.line-item-quantity').addClass('d-none');
                    $('.line-item-quantity-display').removeClass('d-none');
                }
            }

            if (checkoutStages[currentStage] == 'payment') {
                $("#perksProgramContainer").removeClass("d-none");
                $('.remove-button').on('click', function () {
                    $('.perks-points-input').val(0);
                });
                if ($('.perks-points-input').val() && parseInt($('.perks-points-input').val()) > 0) {
                    $(".no-perks-message").addClass("d-none");
                } else {
                    $(".no-perks-message").removeClass("d-none");
                }

            } else {
                $("#perksProgramContainer").addClass("d-none");
            }
        }

        //
        // Local member methods of the Checkout plugin
        //
        var members = {
            // initialize the currentStage variable for the first time
            currentStage: 0,

            /**
             * Set or update the checkout stage (AKA the shipping, billing, payment, etc... steps)
             * @returns {Object} a promise
             */
            updateStage: function () {
                var stage = checkoutStages[members.currentStage];
                var defer = $.Deferred(); // eslint-disable-line
                $.spinner().start();
                if (stage === 'shipping') {
                    //
                    // Clear Previous Errors
                    //
                    formHelpers.clearPreviousErrors('.shipping-form');

                    $('.edq-error').removeClass('d-block');

                    var multiShipChecked = $('#multiShipCheck').prop('checked');
                    var allFormValidated = true;
                    if (multiShipChecked) {
                        for (var m = 0; m < $('.multi-shipping .shipping-form').length; m++) {
                            var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit($('.multi-shipping .shipping-form').get(m));
                            if (!validForm) {
                                allFormValidated = false;
                            }
                        }
                        var validContactForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit($('.single-shipping .shipping-form .card').get(0));

                        if (!validContactForm) {
                            allFormValidated = false;
                        }
                    }

                    var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit($('.single-shipping .shipping-form').get(0));

                    if ((!multiShipChecked && validForm && !$('.single-shipping .shipping-form').find('input:invalid, select:invalid').length)
                        || (multiShipChecked && allFormValidated && !$('.multi-shipping .shipping-form').find('input:invalid, select:invalid').length)
                    ) {
                        var $pickupInputs = $('.pickup-person-form input');
                        var pickupFieldsFilled = [];
                        var pickupFieldsBlank = [];
                        for (var i = 0; i < $pickupInputs.length; i++) {
                            if ($pickupInputs[i].value !== '') {
                                pickupFieldsFilled.push($pickupInputs[i]);
                            } else {
                                pickupFieldsBlank.push($pickupInputs[i]);
                                $('.pickup-person-form input').removeClass('is-invalid');
                                $('.pickup-person-error').addClass('d-none');
                            }
                        }

                        //
                        // Submit the Shipping Address Form
                        //
                        var formSelector = '.single-shipping .shipping-form';
                        var form = $(formSelector);

                        var shippingFormData = form.serialize();

                        $('body').trigger('checkout:serializeShipping', {
                            form: form,
                            data: shippingFormData,
                            callback: function (data) {
                                shippingFormData = data;
                            }
                        });

                        var timezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
                        if (timezone && timezone != 'undefined') {
                            shippingFormData = shippingFormData + "&timezone=" + timezone;
                        }

                        if ($('.shipping-address-block').attr('data-addressvalidedq') == 'true') {
                            shippingFormData = shippingFormData + '&addressvalid=true';
                        }

                        $.ajax({
                            url: form.attr('action'),
                            type: 'post',
                            data: shippingFormData,
                            success: function (data) {
                                if (data.error) {
                                    $.spinner().stop();
                                    if (data.fieldErrors.length) {
                                        data.fieldErrors.forEach(function (error) {
                                            $('body').trigger('checkoutFormError:submit', {
                                                error: error,
                                                formName: 'checkout-shipping'
                                            });
                                        });
                                    }
                                    if (data.fieldErrors.length && isEmpty(data.fieldErrors[0])) {
                                        var errorTxt = $('.shipping-error-text').val();
                                        var errorObj = { "dwfrm_shipping_shippingAddress_addressFields_address2": errorTxt }
                                        data.fieldErrors.push(errorObj);
                                    }
                                    if (data.serverErrors.length) {
                                        data.serverErrors.forEach(function (error) {
                                            $('.error-message').show();
                                            $('.error-message-text').text(error);
                                            scrollAnimate($('.error-message'));
                                        });
                                    }
                                } else {
                                    if (data.isAffirmApplicable) {
                                        $('.affirm-payment-tab-wrapper').removeClass('d-none');
                                    } else {
                                        $('.affirm-payment-tab-wrapper').addClass('d-none');
                                    }
                                }

                                shippingHelpers.methods.shippingFormResponse(defer, data);
                            },
                            error: function (err) {
                                $.spinner().stop();
                                $('#processingOrderModal').modal('hide');
                                if (err.responseJSON.redirectUrl) {
                                    window.location.href = err.responseJSON.redirectUrl;
                                }
                                if (err.responseJSON.error) {
                                    if (typeof err.responseJSON.error === 'object') {
                                        $('.error-message').show();
                                        $('.error-message-text').text(err.responseJSON.error.errorText);
                                        scrollAnimate($('.error-message'));
                                    } else {
                                        $('.error-message').show();
                                        $('.error-message-text').text(err.responseJSON.error);
                                        scrollAnimate($('.error-message'));
                                    }
                                }
                                // Server error submitting form
                                defer.reject(err.responseJSON);
                            }
                        });
                    }

                    if (!validForm) {
                        $.spinner().stop();
                    }

                    return defer;
                } else if (stage === 'payment') {
                    //
                    // Submit the Billing Address Form
                    //

                    formHelpers.clearPreviousErrors('.payment-form');

                    var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit(
                        $('.payment-form-container').get(0)
                    );
                    $('.edq-billing-error').removeClass('d-block');
                    if (!validForm) {
                        $.spinner().stop();
                    }

                    var isVisibleCreditCard = $('.credit-card-selection-new').is(':visible');
                    if (validForm && !$('.payment-form').find('select:invalid, input:invalid').length || !isVisibleCreditCard) {
                        var formItems = $('#dwfrm_billing')
                            .serializeArray()
                            .filter(function (formItem) {
                                if (formItem.value !== '') {
                                    return formItem;
                                }
                            });

                        var paymentForm = '';
                        $('.primary-charge-amount-input').val($('.charge-amount').text().split('$')[1]);
                        formItems.forEach(function (formItemObject, i) {
                            paymentForm += formItemObject.name + '=' + formItemObject.value;
                            if (formItems.length - 1 !== i) {
                                paymentForm += '&';
                            }
                        });

                        $('body').trigger('checkout:serializeBilling', {
                            form: $('#dwfrm_billing'),
                            data: paymentForm,
                            callback: function (data) {
                                paymentForm = data;
                            }
                        });

                        var formItemsShipping = $('#dwfrm_shipping')
                            .serializeArray()
                            .filter(function (formItem) {
                                if (formItem.name === 'dwfrm_billing_addtoemaillist') {
                                    return formItem;
                                }
                            });
                        formItemsShipping.forEach(function (formItemObject, i) {
                            paymentForm += '&' + formItemObject.name + '=' + formItemObject.value;
                        });

                        var formIsValid = true;

                        $('.billing-address-block, .contact-info-block')
                            .find('input, select')
                            .each(function (index, el) {
                                if (!this.validity.valid && $(this).prop('required')) {
                                    $(this).trigger('invalid');
                                    formIsValid = false;
                                }
                            });

                        var isVisibleCreditCardForm = $('.payment-information').is(':visible');
                        // if payment method is credit card
                        if (isVisibleCreditCardForm) {
                            if ($('.payment-information').data('payment-method-id') === 'CREDIT_CARD') {
                                if (!$('.payment-information').data('is-new-payment')) {
                                    var $savedPaymentInstruments = $('.saved-payment-instrument.selected-payment');
                                    $savedPaymentInstruments.each(function (index, el) {
                                        var cvvCode = $(this).find('.saved-payment-security-code');
                                        let grandTotal = parseFloat($('.grand-total-sum').text().replace(/\$/, ''), 10);
                                        let showSorTandC = $('.direct-place-order').hasClass('js-t-and-c-check');
                                        // Usually CVV is not required for orders when Order Total is 0$ after applying gift cards. Exceptional case: We mandate CVV for 0$ orders only when it is a subscription order as we need to do recurring payments
                                        if (cvvCode.length && (grandTotal > 0 || showSorTandC)) {
                                            if (cvvCode.val() === '' || !cvvCode[0].validity.valid) {
                                                cvvCode.trigger('invalid');
                                                if (cvvCode.val() === '') {
                                                    cvvCode.addClass('is-invalid');
                                                    if (cvvCode.parents('.form-group')) {
                                                        $(cvvCode.parents('.form-group')[0]).addClass('custom-invalidation');
                                                    }
                                                    cvvCode.parent().siblings('.invalid-feedback').text('Security Code is required');
                                                }
                                                formIsValid = false;
                                            }
                                        }

                                        if (!$('.primary-card').is('*') || $(this).hasClass('primary-card')) {
                                            paymentForm += '&storedPaymentUUID=' + $(this).data('uuid');
                                            if (cvvCode.length) {
                                                paymentForm += '&securityCode=' + cvvCode.val();
                                            }
                                        }
                                    });
                                } else {
                                    $('#credit-card-content')
                                        .find('input, select')
                                        .each(function (index, el) {
                                            if (!this.validity.valid && $(this).prop('required')) {
                                                $(this).trigger('invalid');
                                                formIsValid = false;
                                            }
                                        });
                                }
                            }
                        }

                        if (!formIsValid) {
                            defer.reject();
                            return defer;
                        }
                        $('#processingOrderModal').modal('show');
                        $('#processingOrderModal').off('shown.bs.modal').on('shown.bs.modal', function (e) {
                            $.ajax({
                                url: $('#dwfrm_billing').attr('action'),
                                method: 'POST',
                                data: paymentForm,
                                success: function (data) {
                                    // look for field validation errors
                                    if (data.error) {
                                        $('#processingOrderModal').modal('hide');
                                        if (data.fieldErrors && data.fieldErrors.length) {
                                            data.fieldErrors.forEach(function (error) {
                                                if (Object.keys(error).length > 0) {
                                                    if ('dwfrm_billing_addressFields_address1' in error) {
                                                        error['dwfrm_billing_addressFields_address1'] = $('.custom-invalidation .invalid-feedback').text();
                                                    }
                                                    formHelpers.loadFormErrors('.payment-form', error);
                                                    Object.keys(error).forEach(function (err) {
                                                        $($('input[name=' + err + ']').parents('.form-group')[0]).addClass('custom-invalidation');
                                                    });
                                                }
                                                if ('dwfrm_billing_creditCardFields_securityCode' in error) {
                                                    $('#securityCodeInvalidMessage').text(error.dwfrm_billing_creditCardFields_securityCode);
                                                }
                                            });
                                        }

                                        if (data.serverErrors && data.serverErrors.length) {
                                            data.serverErrors.forEach(function (error) {
                                                $('.error-message').show();
                                                $('.error-message-text').text(error);
                                                $("html").animate(
                                                    {
                                                        scrollTop: $('.error-message-text').offset().top - 20
                                                    },
                                                    800
                                                );
                                            });
                                        }

                                        if (data.cartError && !data.noRedirect) {
                                            window.location.href = data.redirectUrl;
                                        }

                                        if (data.alreadyAdded) {
                                            $('#cardNumberInvalidMessage').text(data.errorMsg);
                                            $('.cardNumber').addClass('is-invalid');
                                            if ($('.cardNumber').parents('.form-group')) {
                                                $($('.cardNumber').parents('.form-group')[0]).addClass('custom-invalidation');
                                            }
                                            defer.reject();
                                        }

                                        if (data.redeemCouponsError && $('.remove-perks').length > 0) {
                                            $('.remove-perks').trigger('click');
                                        }

                                        $('body').trigger('checkoutFormError:submit', {
                                            error: data,
                                            formName: 'checkout-review'
                                        });
                                        if (data.cartError) {
                                            window.location.href = data.redirectUrl;
                                            defer.reject();
                                        } else {
                                            // go to appropriate stage and display error message
                                            defer.reject(data);
                                        }
                                    } else {
                                        if (data.renderedSelectedPaymentInstruments) {
                                            $('.payment-details').empty().html(data.renderedSelectedPaymentInstruments);
                                        }

                                        if (data.renderedPaymentInstruments) {
                                            var clientSideValidation = require('refArch/components/clientSideValidation');

                                            $('.stored-payments').empty().html(data.renderedPaymentInstruments);
                                            clientSideValidation.init({
                                                continueSelector: 'button.submit-payment',
                                                formSelector: '.user-payment-instruments',
                                                enableContinue: true
                                            });
                                        }

                                        if (
                                            data.customer &&
                                            data.customer.registeredUser &&
                                            data.customer.customerPaymentInstruments.length
                                        ) {
                                            $('.cancel-new-payment').removeClass('checkout-hidden');
                                        }
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                        $('body').trigger('checkout:handleNextStage', 'placeOrder');

                                        if (data.continueUrl) {
                                            var continueUrl = data.continueUrl;
                                            var urlParams = {
                                                ID: data.orderID,
                                                token: data.orderToken
                                            };

                                            continueUrl +=
                                                (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                                                Object.keys(urlParams)
                                                    .map(function (key) {
                                                        return key + '=' + encodeURIComponent(urlParams[key]);
                                                    })
                                                    .join('&');

                                            let gtmStartedEvent = window.dataLayer.find(element => element['gtm.start']);
                                            if (typeof gtag != 'undefined' && window.gtagEnabled && gtmStartedEvent && gtmStartedEvent['gtm.uniqueEventId']) {
                                                var ranAlready = false;
                                                gtag('set', 'user_data', {
                                                    "email": data.customerEmail
                                                });
                                                var gtagSendTo = 'AW-1067780753/JNK8COuDowcQkZWU_QM';
                                                if (window.gtagSendToConversion && window.gtagSendToConversion != 'null') {
                                                    gtagSendTo = window.gtagSendToConversion;
                                                }
                                                gtag('event', 'conversion', {
                                                    'send_to': gtagSendTo,
                                                    'event_callback': function () {
                                                        if (!ranAlready) {
                                                            window.location.href = continueUrl;
                                                            ranAlready = true;
                                                        }
                                                    }
                                                });
                                            } else {
                                                window.location.href = continueUrl;
                                            }
                                        } else if (data.paymentType && data.paymentType == 'Affirm' && $('.payment-information').data('payment-method-id') == 'Affirm') {
                                            var url = $('#affirm-config').data('affirupdateurl');
                                            $.spinner().start();
                                            $.ajax({
                                                url: url,
                                                method: 'GET',
                                                success: function (data) {
                                                    $.spinner().stop();
                                                    $('#vcn-data').data('vcndata', JSON.parse(data.vcndata));
                                                    $('body').trigger('affirm:openvcn');
                                                },
                                                error: function () {
                                                    $('#processingOrderModal').modal('hide');
                                                    $.spinner().stop();
                                                }
                                            });
                                        }
                                        $('#processingOrderModal').modal('hide');
                                        defer.reject();
                                    }
                                },
                                error: function (err) {
                                    $.spinner().stop();
                                    $('#processingOrderModal').modal('hide');
                                    if (err.responseJSON.redirectUrl) {
                                        window.location.href = err.responseJSON.redirectUrl;
                                    }
                                    if (err.responseJSON.error) {
                                        if (typeof err.responseJSON.error === 'object') {
                                            $('.error-message').show();
                                            $('.error-message-text').text(err.responseJSON.error.errorText);
                                            scrollAnimate($('.error-message'));
                                        } else {
                                            $('.error-message').show();
                                            $('.error-message-text').text(err.responseJSON.error);
                                            scrollAnimate($('.error-message'));
                                        }
                                    }
                                }
                            });
                        });
                    }

                    return defer;
                }
                var p = $('<div>').promise(); // eslint-disable-line
                setTimeout(function () {
                    p.done(); // eslint-disable-line
                    $.spinner().stop();
                }, 500);
                return p; // eslint-disable-line
            },

            /**
             * Initialize the checkout stage.
             *
             * TODO: update this to allow stage to be set from server?
             */
            initialize: function () {
                // set the initial state of checkout
                if (window.location.href && window.location.href.indexOf('stage=payment#payment') > 0 && $('#null-addr-flag') && $('#null-addr-flag').length > 0) {

                    localStorage['redirected_from_payment'] = true;
                    window.location.href = window.location.href.replace('stage=payment#payment', 'stage=shipping#shipping');

                } else if (window.location.href && window.location.href.indexOf('stage=shipping#shipping') && localStorage['redirected_from_payment'] && localStorage['redirected_from_payment'] === 'true') {

                    localStorage['redirected_from_payment'] = '';
                    $('body').append('<div class="add-to-cart-messages"></div>');
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center" role="alert">'
                        + "Please enter shipping details or log back in prior to entering your payment information" +
                        '</div>'
                    );

                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 5000);

                }

                members.currentStage = checkoutStages.indexOf($('.data-checkout-stage').data('checkout-stage'));
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);

                //
                // Handle Payment option selection
                //
                $('input[name$="paymentMethod"]', plugin).on('change', function () {
                    $('.credit-card-form').toggle($(this).val() === 'CREDIT_CARD');
                });

                //
                // Handle Next State button click
                //
                $(plugin).on('click', '.next-step-button button:not(.dw-apple-pay-button)', function (e) {
                    // Hide No Perks avaible message
                    $(".no-perks-message").addClass("d-none");
                    if (e.currentTarget.classList.contains('js-t-and-c-check') && !e.currentTarget.dataset.tcApproved) {
                        return;
                    }

                    members.nextStage();
                });

                //
                // Handle Edit buttons on shipping and payment summary cards
                //
                $('.shipping-summary .edit-button', plugin).on('click', function () {
                    if ($(this).hasClass('isBuyNow')) {
                        window.location.href = $('#buyNowReturnUrl').val();
                        return;
                    }

                    if (!$('#checkout-main').hasClass('multi-ship')) {
                        $('body').trigger('shipping:selectSingleShipping');
                    }
                    summaryHelpers.revertPlaceOrderView();
                    summaryHelpers.updateHeaderStage('shipping');
                    members.gotoStage('shipping');
                });

                $('.payment-summary .edit-button', plugin).on('click', function () {
                    if ($(this).hasClass('isBuyNow')) {
                        window.location.href = $('#buyNowReturnUrl').val();
                        return;
                    }

                    summaryHelpers.revertPlaceOrderView();
                    summaryHelpers.updateHeaderStage('payment');
                    members.gotoStage('payment');
                    if (window.paypalUtils) {
                        var $paypalButton = $('.js_paypal_button_on_billing_form');
                        var config = $paypalButton.data('paypalConfig');
                        paypalUtils.initButton(config, $paypalButton[0]);
                    }
                });

                //
                // remember stage (e.g. shipping)
                //
                updateUrl(members.currentStage);

                //
                // Listen for foward/back button press and move to correct checkout-stage
                //
                $(window).on('popstate', function (e) {
                    //
                    // Back button when event state less than current state in ordered
                    // checkoutStages array.
                    //
                    if (e.state === null || checkoutStages.indexOf(e.state) < members.currentStage) {
                        members.handlePrevStage(false);
                    } else if (checkoutStages.indexOf(e.state) > members.currentStage) {
                        // Forward button  pressed
                        members.handleNextStage(false);
                    }
                });

                //
                // Set the form data
                //
                plugin.data('formData', formData);


                $('#smsOptIn').change(function () {
                    if (this.checked) {
                        $('#sms-disclaimer').removeClass("d-none");
                    } else {
                        $('#sms-disclaimer').addClass("d-none");
                    }
                });
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             */
            nextStage: function () {
                var promise = members.updateStage();

                promise.done(function () {
                    $.spinner().stop();
                    // Update UI with new stage
                    members.handleNextStage(true);
                });

                promise.fail(function (data) {
                    $.spinner().stop();
                    // show errors
                    if (data) {
                        if (data.errorStage) {
                            members.gotoStage(data.errorStage.stage);
                            summaryHelpers.revertPlaceOrderView();

                            if (data.errorStage.step === 'billingAddress') {
                                var $billingAddressSameAsShipping = $(
                                    'input[name$="_shippingAddressUseAsBillingAddress"]'
                                );
                                if ($billingAddressSameAsShipping.is(':checked')) {
                                    $billingAddressSameAsShipping.prop('checked', false);
                                }
                            }
                        }

                        if (data.errorMessage) {
                            $('.error-message').show();
                            $('.error-message-text').text(data.errorMessage);
                        }

                        if (data.errorStage || data.errorMessage) {
                            $("html").animate(
                                {
                                    scrollTop: $('.error-message-text').offset().top - 20
                                },
                                800
                            );
                        }
                    }
                });
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             *
             * @param {boolean} bPushState - boolean when true pushes state using the history api.
             */
            handleNextStage: function (bPushState) {
                if (members.currentStage < checkoutStages.length - 1) {
                    // move stage forward
                    members.currentStage++;

                    var newStage = checkoutStages[members.currentStage];

                    summaryHelpers.updateHeaderStage(newStage);

                    //
                    // show new stage in url (e.g.payment)
                    //
                    if (bPushState) {
                        $('body').trigger('checkout:handleNextStage', newStage);
                        updateUrl(members.currentStage);
                    }
                }

                // Set the next stage on the DOM
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
            },

            /**
             * Previous State
             */
            handlePrevStage: function () {
                if (members.currentStage > 0) {
                    // move state back
                    members.currentStage--;
                    updateUrl(members.currentStage);

                    var newStage = checkoutStages[members.currentStage];
                    summaryHelpers.updateHeaderStage(newStage);
                }

                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
            },

            /**
             * Use window history to go to a checkout stage
             * @param {string} stageName - the checkout state to goto
             */
            gotoStage: function (stageName) {
                members.currentStage = checkoutStages.indexOf(stageName);
                updateUrl(members.currentStage);
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
                billingHelpers.methods.updateChargeAmount();
            }
        };

        //
        // Initialize the checkout
        //
        members.initialize();

        return this;
    };
})(jQuery);

var exports = {
    initialize: function () {
        $('#checkout-main').checkout();
        //apple pay
        $(document).ready(function () {
            if (window.dw &&
                window.dw.applepay &&
                window.ApplePaySession &&
                window.ApplePaySession.canMakePayments()) {
                $('body').addClass('apple-pay-enabled');
            }
            if ($('.payment-form-fields').find('.paypal-already-handled-account').length) {
                $('.paypal-tab').click();
            }
        });
        $('body').on('click', '.add-address2', function (e) {
            e.preventDefault();
            $(this).closest('fieldset').find('.address2-js').toggleClass('d-none');
            $(this).toggleClass('d-none');
        });
        require('refArch/login/loginFlyout').loginFlyout();
    },

    updateCheckoutView: function () {
        $('body').on('checkout:updateCheckoutView', function (e, data) {
            shippingHelpers.methods.updateMultiShipInformation(data.order);
            summaryHelpers.updateTotals(data.order.totals, data.remainingPaymentAmount);

            $('.affirm-as-low-as').attr(
                'data-amount',
                (parseFloat(data.order.totals.grandTotal.replace(/[^0-9.]/g, ''), 10) * 100).toFixed(2)
            );
            //$(document).trigger('affirm:update');
            var customerName = "";
            var customerNameAdded = false;
            data.order.shipping.forEach(function (shipping) {
                shippingHelpers.methods.updateShippingInformation(shipping, data.order, data.customer, data.options);
                shippingHelpers.methods.updateShippingSummaryEmail(shipping, data.order);

            });

            billingHelpers.methods.updateBillingInformation(data.order, data.customer, data.options);
            // summaryHelpers.updateOrderProductSummaryInformation(data.order, data.options); @TODO check if we need this
            data.order.shipping.forEach(function (shipping) {
                shipping.productLineItems.items.forEach(function (lineItem) {
                    if (lineItem.bopisToSts) {
                        $('.product-line-item[data-product-line-item="' + lineItem.UUID + '"')
                            .find('.bopis-error')
                            .removeClass('d-none');
                    }
                    var fn = ($('#shippingFirstName' + lineItem.UUID) && $('#shippingFirstName' + lineItem.UUID).val()) || ($('#shippingFirstNamedefault') && $('#shippingFirstNamedefault').val()) || '';
                    var ln = ($('#shippingLastName' + lineItem.UUID) && $('#shippingLastName' + lineItem.UUID).val()) || ($('#shippingLastNamedefault') && $('#shippingLastNamedefault').val()) || '';
                    customerName = fn + ' ' + ln;
                    $("#customerName-" + lineItem.UUID).text(customerName);
                });
            });

            data.order.items.items.forEach(function (item) {
                if (item.shippingAddress && item.shippingAddress !== null && item.shippingAddress.indexOf('null') === -1) {
                    var fn = ($('#shippingFirstName' + item.UUID) && $('#shippingFirstName' + item.UUID).val()) || ($('#shippingFirstNamedefault') && $('#shippingFirstNamedefault').val()) || '';
                    var ln = ($('#shippingLastName' + item.UUID) && $('#shippingLastName' + item.UUID).val()) || ($('#shippingLastNamedefault') && $('#shippingLastNamedefault').val()) || '';
                    var name = fn + ' ' + ln;
                    $("#customerName-" + item.UUID).text(name);
                    var addrHeader = document.createElement('span');
                    var lineBreak = document.createElement('br');
                    $(addrHeader).text('Address:');
                    if ($("#shippingAddress-" + item.UUID).parents('.omni-category').attr('data-store') == "shipToAddress") {
                        $("#shippingAddress-" + item.UUID).html(addrHeader);
                        $("#shippingAddress-" + item.UUID).append(lineBreak);
                        $("#shippingAddress-" + item.UUID).append(item.shippingAddress);
                    }
                }
            });

            summaryHelpers.updatePhoneInfo(data.order);
            if (data.order.totals && data.order.totals.remainingPaymentAmount) {
                billingHelpers.methods.updateChargeAmount("" + data.order.totals.remainingPaymentAmount);
            } else {
                billingHelpers.methods.updateChargeAmount(data.order.priceTotal);
            }

            summaryHelpers.updateShippingSummaryInfo(data.order.orderPickupSummaryHTML);
            var applicableShippingMethodIds = {};
            data.order.shipping.forEach(function (shippingItem) {
                applicableShippingMethodIds[shippingItem.UUID] = [];
                shippingItem.applicableShippingMethods.forEach(function (applicableShippingMethod) {
                    applicableShippingMethodIds[shippingItem.UUID].push(applicableShippingMethod.ID.toString());
                });
            });

            data.order.items.items.forEach(function (item) {
                $('.product-line-item[data-product-line-item="' + item.UUID + '"]').find('.promise-date').each(function (idx, elem) {
                    $(elem).attr('data-uuid', item.shipmentUUID);
                });
            });
            if (data.order && data.order.sortedLineItems) {
                data.order.sortedLineItems.forEach(function (shipment) {
                    shipment.items.forEach(function (item) {
                        if (item.promiseDatesHtml) {
                            $('.product-line-item[data-product-line-item="' + item.UUID + '"]').find('.promise-dates').html(item.promiseDatesHtml);
                        }
                    })
                })
            }
            Object.keys(applicableShippingMethodIds).forEach(function (shipmentUUID) {
                $('.promise-date[data-uuid="' + shipmentUUID + '"]').addClass('d-none');
                $('.promise-date[data-uuid="' + shipmentUUID + '"]').each(function (idx, elem) {
                    if ($(elem).data('id') && ((applicableShippingMethodIds[shipmentUUID].indexOf($(elem).data('id').toString()) !== -1) || ($(elem).data('id').toString() == '005' && applicableShippingMethodIds[shipmentUUID].indexOf('3') !== -1))) {
                        $(elem).removeClass('d-none');
                    }
                });
            });

            $(document).trigger('promiseDates:loaded');

            $('body').trigger('checkout:updateCheckoutViewAffirm', data);
            $('.additional-info:not(.accout-modal-link-info)').addClass('d-none');
            if (!!data.order.shipping[0].shippingAddress) {
                $('.order-total-summary .sales-tax-item .order-receipt-label').empty().html('<span>' + $('.order-total-summary .sales-tax-item .order-receipt-label').data('taxlabel') + '</span>')
            }
            if (data.order && data.order.customerZipCodeMsg) {
                $('.additional-info:not(.accout-modal-link-info)').empty().html(data.order.customerZipCodeMsg);
                $('.additional-info:not(.accout-modal-link-info)').removeClass('d-none');
            }
            if (data.order && data.order.customerZipCodeMsgOrderDetails) {
                $('.customerZipCode').empty().html(data.order.customerZipCodeMsgOrderDetails);
                $('.customerZipCode').removeClass('d-none');
            }
        });
    },

    updateLoadedItems: function () {
        $(function () {
            var elements = $('.line-item-option>span');
            elements && Array.from(elements).forEach(function (elem) {
                if ($(elem).attr('id') && $(elem).attr('id').indexOf('shippingAddress-') > -1) {
                    var uuid = $(elem).attr('id').toString().split('shippingAddress-').pop();
                    var fn = ($('#shippingFirstName' + uuid) && $('#shippingFirstName' + uuid).val()) || ($('#shippingFirstNamedefault') && $('#shippingFirstNamedefault').val()) || '';
                    var ln = ($('#shippingLastName' + uuid) && $('#shippingLastName' + uuid).val()) || ($('#shippingLastNamedefault') && $('#shippingLastNamedefault').val()) || '';
                    var name = fn + ' ' + ln;
                    $("#customerName-" + uuid).text(name);
                }
            });
        });
    },

    loadedPromiseDates: function () {
        var promiseUrl = $('.js-checkout-page').attr('data-promise-url');
        if (promiseUrl && $('.product-summary-block .view-order-details-wrapper').length) {
            $.ajax({
                url: promiseUrl,
                method: 'GET',
                success: function (response) {
                    $('.product-line-item').each(function (idx, elem) {
                        $(elem).find('.checkout-promise-dates-wrapper').attr('data-has-promise-date', false);
                    });
                    Object.keys(response).forEach(function (uuid) {
                        $('.promise-dates.product-line-item[data-product-line-item="' + uuid + '"]').find('.checkout-promise-dates-wrapper').attr('data-has-promise-date', true).html(response[uuid]);
                    });
                    $('.checkout-promise-dates-wrapper[data-has-promise-date="true"]').each(function (idx, elem) {
                        $(elem).parents('.estimate-promise-date-checkout').removeClass('d-none');
                    });
                    $('.checkout-promise-dates-wrapper[data-has-promise-date="false"]').each(function (idx, elem) {
                        if ($(elem).hasClass('ship-to-store')) {
                            $(elem).parent('.estimate-promise-date-checkout').removeClass('d-none');
                        }
                    });
                    $(document).trigger('promiseDates:loaded');
                }
            })
        }
    },
    observeStickyButton: function () {
        var checkoutButton = $('.step-button-container');
        var footerContent = document.querySelector('#footercontent');

        function createObserver() {
            var observer;

            var options = {
                root: null,
                rootMargin: "0px",
                threshold: 0.01,
            };

            observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(footerContent);
        }

        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    $(checkoutButton).removeClass('unstick');
                } else {
                    $(checkoutButton).addClass('unstick');
                }
            });
        }

        createObserver();
    }
};

//clearing the form errors on model close
$(document).ready(function () {
    $(".login-model-close").click(function () {
        var form = $('.registration');
        $(form).find('.form-control.is-invalid').removeClass('is-invalid');
        $(form).find('.form-group.custom-invalidation').removeClass('custom-invalidation');
        form = $('.login');
        $(form).find('.form-control.is-invalid').removeClass('is-invalid');
        $(form).find('.form-group.custom-invalidation').removeClass('custom-invalidation');
    });

});

[billingHelpers, shippingHelpers, addressHelpers].forEach(function (library) {
    Object.keys(library).forEach(function (item) {
        if (typeof library[item] === 'object') {
            exports[item] = $.extend({}, exports[item], library[item]);
        } else {
            exports[item] = library[item];
        }
    });
});

module.exports = exports;
