'use strict';

var base = require('base/checkout/summary');

function updatePlaceOrderView() {
    $('.summary-submit-bottom-desktop').before($('.product-summary-block'))
    $('.side-checkout-col').append($('.checkout-summary-block'));
    $('.help-info').addClass('d-none');
    $('.shipping-method-title').parents('.offset-md-6.summary-col').removeClass('offset-md-6');
    if ($('.pickup-summary').length) {
        $('.pickup-summary').removeClass('d-none');
    }
    $('.desktop-order-total').addClass('d-none d-sm-block');
    $('.mobile-order-total').addClass('d-block d-sm-none').removeClass('d-none');
}

function updateHeaderStage(newStage) {
    var stepActive = (newStage === 'payment' || newStage === 'placeOrder') ? true : false;

    $('.js-step-billing').each(function (index, element) {
        if (stepActive) {
            $(element).addClass('active');
        } else {
            $(element).removeClass('active');
        }
    })

    Array.from(document.querySelectorAll('#refill .refill-info-links'))
        .forEach(function (linkEle) {
            linkEle.remove();
        });
}

function revertPlaceOrderView() {
    $('.side-checkout-col').append($('.product-summary-block'))
    $('.main-checkout-col').prepend($('.checkout-summary-block'));
    $('.help-info').removeClass('d-none');
    $('.shipping-method-title').parents('.summary-col').addClass('offset-md-6');
    if ($('.pickup-summary').length) {
        $('.pickup-summary').addClass('d-none');
    }
    $('.desktop-order-total').removeClass('d-none d-sm-block');
    $('.mobile-order-total').removeClass('d-block d-sm-none').addClass('d-none');
}

/**
 * Update phone in summary based on data in shipping form
 * @param {Object} order order object
 * @void
 */
function updatePhoneInfo(order) {
    order.shipping.forEach(function (shippingLineItem) {
        if (shippingLineItem.fromStoreId == null) {
            var phone = (shippingLineItem && shippingLineItem.shippingAddress && shippingLineItem.shippingAddress.phone) ? shippingLineItem.shippingAddress.phone : '';
            $('.address-summary .address-phone').empty().html(phone);
        }
    });
}

/**
 * Update order pickup summary HTML block if html in param not equal null
 * @param {string} orderPickupSummaryHTML order pickup summary HTML
 * @void
 */
function updateShippingSummaryInfo(orderPickupSummaryHTML) {
    if (orderPickupSummaryHTML) {
        $('#order-pickup-summary-block').empty().html(orderPickupSummaryHTML);
    }
}

/**
 * updates the totals summary
 * @param { Array } totals - the totals data
 * @param { Object } remainingPaymentAmount -Remaning Amount
*/
function updateTotals(totals, remainingPaymentAmount) {
    $('.shipping-total-cost').text(totals.totalShippingCost);
    if (totals.freeShipping) {
        $('.shipping-cost-non-free').addClass('d-none');
        $('.shipping-cost-free').removeClass('d-none');
        $('.shipping-cost-free').addClass('d-lg-flex');
        $('.shipping-cost-free').addClass('d-sm-block');
    } else {
        $('.shipping-cost-free').addClass('d-none');
        $('.shipping-cost-non-free').removeClass('d-none');
        $('.shipping-cost-free').removeClass('d-lg-flex');
        $('.shipping-cost-free').removeClass('d-sm-block');
    }

    $('.tax-total').text(totals.totalTax);
    $('.sub-total').text(totals.subTotal);
    $('.grand-total-sum').text(totals.grandTotal);
    $('.order-discount-total').text('- ' + totals.orderDiscountWithoutPerks.formatted);
    $('.shipping-discount-total').text('- ' + totals.shippingLevelDiscountTotal.formatted);
    if (!remainingPaymentAmount && (totals.remainingPaymentAmount || totals.remainingPaymentAmount == 0)) {
        remainingPaymentAmount = totals.remainingPaymentAmount;
    }
    if (remainingPaymentAmount || remainingPaymentAmount == 0) {
        $('.gift-payment-total .grand-total-sum').text('$' + new Number(remainingPaymentAmount).toFixed(2));
        $('.charge-amount').text('$' + new Number(remainingPaymentAmount).toFixed(2));

        if ($('.adjustedTotalWrapper .adjusted-total').length > 0) {
            $('.adjustedTotalWrapper .adjusted-total').text('$' + new Number(remainingPaymentAmount).toFixed(2));
        }
    }

    if (totals.freightCharges) {
        $('.freight-total-cost').html(totals.freightCharges);
    }

    if (totals.unpaidTotals == 0) {
        $('.zero-total-msg').each(function (idx, elem) {
            $(elem).removeClass('d-none');
        });

        $('.redeem-credits:not(.disabled)').attr('disabled', 'disabled');
        $('.redeem-perks:not(.disabled)').attr('disabled', 'disabled');
    } else {
        $('.zero-total-msg').each(function (idx, elem) {
            $(elem).addClass('d-none');
        });
        $('.redeem-credits:not(.disabled)').removeAttr('disabled');
        $('.redeem-perks:not(.disabled)').removeAttr('disabled');
    }
}

function updateCartPoolPerks(subTotal, hasWarranty) {
    // Pool perks
    var subTotalWithoutCurrency = Math.floor(subTotal);
    var hasWarrantyProduct = hasWarranty;
    var poolPerksDetails = $('.js-cart-perks-wrapper-details');
    var poolPerksMinTotal = 19;
    var poolPerksRate = 20;

    if (subTotalWithoutCurrency > poolPerksMinTotal) {
        if (hasWarrantyProduct) {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + poolPerksDetails.attr('data-static-reward-currency') + Math.floor(subTotalWithoutCurrency / poolPerksRate) + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shippingandwarranty'))
        } else {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + poolPerksDetails.attr('data-static-reward-currency') + Math.floor(subTotalWithoutCurrency / poolPerksRate) + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shipping'))
        }
    } else {
        if (hasWarrantyProduct) {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + (subTotalWithoutCurrency % poolPerksRate) + ' points' + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shippingandwarranty'))
        } else {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + (subTotalWithoutCurrency % poolPerksRate) + ' points' + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shipping'))
        }
    }
}


base.updatePlaceOrderView = updatePlaceOrderView;
base.updateHeaderStage = updateHeaderStage;
base.revertPlaceOrderView = revertPlaceOrderView;
base.updatePhoneInfo = updatePhoneInfo;
base.updateShippingSummaryInfo = updateShippingSummaryInfo;
base.updateTotals = updateTotals;
base.updateCartPoolPerks = updateCartPoolPerks;

module.exports = base;
