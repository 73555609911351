'use strict';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject');
    var textYes = $('.tracking-consent').data('accepttext');
    var textNo = $('.tracking-consent').data('rejecttext');
    var textHeader = $('.tracking-consent').data('heading');

    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="consent-tracking" role="dialog" style="display: block;">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + textHeader
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer">'
        + '<div class="button-wrapper">'
        + '<button class="affirm btn btn-primary" data-url="' + urlAccept + '">'
        + textYes
        + '</button>'
        + '<button class="decline btn btn-primary" data-url="' + urlReject + '">'
        + textNo
        + '</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $.spinner().start();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('#consent-tracking').find('.modal-body').html(response);
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking .button-wrapper button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });
}

/**
 * Requirement is to show Iframe Email Promo Consent above Privacy Consent Banner
 * We are updating oneTrustBanner z-Index to be one lower than emailSignUpPromo.
 * Iframe Iframe Email Promo gets Injected on page later
 * 
 * Notes: 
 * emailSignUpPromo z-Index changes during resize event.
 * oneTrustBanner z-Index is 2147483645 which is almost maximum 32-bit signed integer value.
 * 
 */
function promoAboveConsent() {
    try {
        var hasOneTrustBanner = $('#onetrust-banner-sdk.otFlat').length;
        //For Performance - Execute Logic only when have One Trust Banner
        if (hasOneTrustBanner) {
            var oneTrustBanner = $('#onetrust-banner-sdk.otFlat');
            var emailSignUpPromoLPM = $('iframe[title^="Email Signup"]');
            var hasEmailSignUpPromoLPM = emailSignUpPromoLPM.length;
            var emailSignUpPromoITS = $('iframe[title^="RESPONSIVE WELCOME"]');
            var hasEmailSignUpPromoITS = emailSignUpPromoITS.length;
            if (hasEmailSignUpPromoLPM) {
                let promoZindex = parseInt(emailSignUpPromoLPM.parent().css('z-index'));
                oneTrustBanner.css('z-index', promoZindex - 1);
            } else if (hasEmailSignUpPromoITS) {
                let promoZindex = parseInt(emailSignUpPromoITS.parent().css('z-index'));
                oneTrustBanner.css('z-index', promoZindex - 1);
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function observerCallback(mutationsList) {
    try {
        var startsWithValidValue = false;
        for (var i = 0; i < mutationsList.length; i++) {
            var mutation = mutationsList[i];
            if (mutation.type === 'childList') {
                // Loop through nodes to check if they are iframes
                for (var j = 0; j < mutation.addedNodes.length; j++) {
                    var node = mutation.addedNodes[j];
                    //Check Whether Iframe with Title Email Sign Up has been added
                    if (node.tagName === 'IFRAME' && node.getAttribute('title')) {
                        var titleAttribute = node.getAttribute('title');
                        //LPM title is Email Signup and ITS is RESPONSIVE WELCOME
                        var possibleValues = ['Email Signup', 'RESPONSIVE WELCOME'];
                        // Check if the 'title' attribute starts with any of the values in the array
                        startsWithValidValue = possibleValues.some(function (value) {
                            return titleAttribute && titleAttribute.indexOf(value) === 0;
                        });
                    }
                }
            }
        }
        if (startsWithValidValue) {
            promoAboveConsent();
        }
    } catch (e) {
        console.error(e);
    }
}


module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }
    //Performance Reason we execute only when Consent Modal is not closed by Customer
    if (document.cookie.indexOf("OptanonAlertBoxClosed") === -1) {
        // Create an observer instance with a callback function
        const observer = new MutationObserver(observerCallback);
        const targetNode = document.body;
        // Start observing Body target node for changes in its children (subtree: true to observe all descendants)
        observer.observe(targetNode, { childList: true, subtree: true });
    }
    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }
};
