'use strict';

require('jquery.waitforimages');
var base = require('../product/base');
var focusHelper = require('base/components/focus');
var timeout;
var intersectionSticky = 0;
let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;


/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = (url.indexOf('warranty') > -1) ? url.replace('warranty', '') : url;
    if (params && params.isWarranty) {
        newUrl += ('Warranty-Remove' + '?pid=' + params.uuid);
    } else {
        newUrl +=
            (newUrl.indexOf('?') !== -1 ? '&' : '?') +
            Object.keys(params)
                .map(function (key) {
                    return key + '=' + encodeURIComponent(params[key]);
                })
                .join('&');
    }

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml =
                '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' +
                data.valid.message +
                '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart')
                .empty()
                .append(
                    '<div class="row"> ' +
                    '<div class="col-12 text-center"> ' +
                    '<h1>' +
                    data.resources.emptyCartMsg +
                    '</h1> ' +
                    '</div> ' +
                    '</div>'
                );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else if ((data.isRegisteredCustomer == false && data.hasABHProduct) || (data.isRegisteredCustomer == true && data.isABHMember && data.hasABHProduct)) {
        $('.checkout-btn').addClass('disabled');
        $('.checkout-btn').addClass('sor-disabled')
        $('.checkout-btn').attr('disabled', 'disabled');
        $('.sor-checkout-msg').removeClass('d-none');
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.checkout-btn').removeClass('sor-disabled')
        $('.checkout-btn').removeAttr('disabled');
        $('.sor-checkout-msg').addClass('d-none');
    }
}
/**
 * updates the warranty price based on quantity change
 * @param {Object} data - AJAX response from the server
 * @param currentObj - current element Object
 */
function updateWarranty(currentObj, data) {
    var staticFree = $('.product-cards-inner .warranty-price.custom-warranty').attr('data-static-free');

    data.items.forEach(function (item) {
        if (item.options) {
            item.options.forEach(function (curOption) {
                currentObj.parents('.product-line-item.uuid-' + item.UUID).find('.warranty-price.custom-warranty').text(curOption.price.formatted.slice(-4) === '0.00' || data.isRegisteredCustomer === true ? staticFree : curOption.price.formatted);
                if (currentObj.parents('.product-line-item.uuid-' + item.UUID).find('.discount-price').length && curOption.discountPrice) {
                    currentObj.parents('.product-line-item.uuid-' + item.UUID).find('.discount-price').text(curOption.discountPrice.formatted.slice(-4) === '0.00' || data.isRegisteredCustomer === true ? staticFree : curOption.discountPrice.formatted)
                }

                if (data.isRegisteredCustomer !== true) {
                    currentObj.parents('.product-info.uuid-' + item.UUID).find('.warranty-price.custom-warranty').text(curOption.price.formatted.slice(-4) === '0.00' ? staticFree : curOption.price.formatted);
                    if (currentObj.parents('.product-info.uuid-' + item.UUID).find('.warranty-price.discount-price').length && curOption.discountPrice) {
                        currentObj.parents('.product-info.uuid-' + item.UUID).find('.warranty-price.discount-price').text(curOption.discountPrice.formatted.slice(-4) === '0.00' ? staticFree : curOption.discountPrice.formatted)
                    }
                }
            })
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost:not(.checkout-shipping)').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    if ($('.grand-total-sum').length) {
        $('.grand-total-sum').empty().append(data.totals.grandTotal);
    } else {
        $('.grand-total').empty().append(data.totals.grandTotal);
    }
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.row.perk-discount .order-perk-total').empty().append('-' + data.totals.perkCouponsDiscount.formatted)
    $('.cart-header-subtotal').empty().append(data.totals.subTotal + ' ' + $('.cart-header-subtotal').data('subtotal-text') + ' - ');
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    if (data.totals.perkCouponsDiscount) {
        $('.used-coupons-amount').text(data.totals.perkCouponsDiscount.formatted);
        $('.remaining-perks-value').text(data.totals.remainingPerksValue.formatted);
    }

    var msg;
    if (data.numItems > 1) {
        msg = $('.sub-total').data('msg') ? $('.sub-total').data('msg').replace(/#/g, data.numItems) : null;
    } else {
        msg = $('.sub-total').data('msg-singular') ? $('.sub-total').data('msg-singular').replace(/#/g, data.numItems) : null;
    }
    if (msg) {
        $('.sub-total').closest('.row').find('.subtotal-label').text(msg);
    }

    if (data.totals.freightCharges) {
        var queryStr = data.queryString || null;
        var uuidIndex = queryStr ? queryStr.indexOf('uuid=') : -1;
        var itemUUID = (uuidIndex !== -1 && uuidIndex + 5 < queryStr.length) ? queryStr.substring(uuidIndex + 5, queryStr.length) : null;
        if (itemUUID) {
            var itemWithFreight = data.items.find(function (itemObj) {
                return itemObj.UUID == itemUUID
            });
            $('.label-deliveryMethod' + itemUUID + ' .ltlShippingCost').empty().append('(+' + itemWithFreight.freightCost + ')');
        }
        $('.freight-cost').empty().append(data.totals.freightCharges);
    } else {
        $('.freight-cost').closest('.row').empty();
    }

    if (data.totals.freeShippingHtml && data.totals.freeShippingHtml.trim()) {
        $('.js-shipping-section:not(.checkout-shipping)').empty().append(data.totals.freeShippingHtml);
        $('.shipping-cost:not(.checkout-shipping)').closest('div.row').hide();
    }

    if (!data.totals.freeShipping && data.totals.totalShippingCostValue !== 0) {
        $('.shipping-cost:not(.checkout-shipping)').closest('div.row').show();
    } else {
        $('.shipping-cost:not(.checkout-shipping)').closest('div.row').hide();
    }


    var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
    $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
    if (window.affirmLimits) {
        var isWithinAffirmLimit = (parseFloat(totalCalculated) >= parseFloat(affirmLimits.min) && parseFloat(totalCalculated) <= parseFloat(affirmLimits.max))
        if (isWithinAffirmLimit) {
            $('#js-affirm-checkout-now').show();
            $('.affirm-as-low-as').removeClass('d-none');
        } else {
            $('#js-affirm-checkout-now').hide();
            $('.affirm-as-low-as').attr('data-amount', NaN);
            $('.affirm-as-low-as').addClass('d-none');
        }
        try {
            if (typeof affirm.ui.refresh !== 'undefined') {
                affirm.ui.refresh();
            }
        } catch (error) {
            //ie does not support affirm.ui.refresh() and causes checkout to freeze
            window.location.reload();
        }
    }

    if (data.totals.orderDiscountWithoutPerks.value > 0) {
        $('.order-discount-total')
            .empty()
            .append('- ' + data.totals.orderDiscountWithoutPerks.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0 && !data.totals.freeShipping) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total')
            .empty()
            .append('- ' + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.totals.freeShipping) {
        $('.shipping-cost-non-free').addClass('d-none');
        $('.shipping-cost-free').removeClass('d-none');
    } else {
        $('.shipping-cost-free').addClass('d-none');
        $('.shipping-cost-non-free').removeClass('d-none');
    }

    if (!data.isBopisOnly && data.isTaxCalculated) {
        $('.additional-info:not(.accout-modal-link-info)').removeClass('d-none');
        $('.additional-info-checkout').addClass('d-none');
    } else if (data.isBopisOnly) {
        $('.additional-info-checkout').addClass('d-none');
        $('.additional-info:not(.accout-modal-link-info)').addClass('d-none');
        $('.tax-tbd').empty().append(data.totals.totalTax);
    }

    if (data.totals.shipmentTypesData && data.totals.shipmentTypesData.length) {
        $('.instore-shipping-cost').closest('.row').addClass('d-none')
        data.totals.shipmentTypesData.forEach(function (shipmentType) {
            if (shipmentType.type === 'instore') {
                $('.instore-shipping-cost').empty().append(shipmentType.formatted);
                $('.instore-shipping-cost').closest('.row').removeClass('d-none')
            } else if (shipmentType.type === 'default') {
                $('.shipping-cost').empty().append(shipmentType.formatted);
                $('.shipping-cost').removeClass('applied-promotion-discount');
                if (shipmentType.formatted === 'Free') {
                    $('.shipping-cost').addClass('applied-promotion-discount');
                    $('.shipping-cost:not(.checkout-shipping)').closest('div.row').show();
                }
            }
        });
    }

    data.items.forEach(function (item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID)
                .empty()
                .append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            var renderedPrice = item.priceTotal.renderedPrice;
            if ('enableMemberShipProgram' in item && item.enableMemberShipProgram) {
                if (item.SubscriptionOptions.monthIntervals && item.SubscriptionOptions.monthIntervals.selected) {
                    renderedPrice += '<div class="membership-period ml-1">/ Month</div>';
                } else if (item.SubscriptionOptions.weekIntervals && item.SubscriptionOptions.weekIntervals.selected) {
                    renderedPrice += '<div class="membership-period ml-1">/ Week</div>';
                }
            }
            $('.item-total-' + item.UUID)
                .empty()
                .append(renderedPrice);

            // SOR
            var refillTextPrices = document.querySelectorAll('.uuid-' + item.UUID + ' .js-refill-price');

            if (refillTextPrices.length) {
                refillTextPrices.forEach(function (priceEle) {
                    var price = item.priceTotal.nonAdjustedPrice || item.priceTotal.price;
                    priceEle.textContent = price;
                });
            }
        }
        if (item.bopisToSts) {
            $('.uuid-' + item.UUID).find('.bopis-error').removeClass('d-none');
        }
    });
    var wrapper = $('.fulfillment-type-wrapper');
    wrapper.each(function () {
        var $wrapper = $(this);
        var inputs = $wrapper.next('.omni-category').find('.quantity-selector__value');
        // Initialize a variable to store the count
        var count = 0;
        // Iterate through the input elements
        inputs.each(function () {
            // Check if the input element is visible
            if ($(this).is(':visible')) {
                // Add the value to the count
                count += parseInt($(this).val());
            }
        });
        count += $wrapper.next('.omni-category').find('.product-info.product-info-membership').length;
        $wrapper.find('.item-length').text(count.toString());
        var numeral = count < 2 ? 'item' : 'items';
        $wrapper.find('.item-numeral').text(numeral);
    });

    var checkoutWrapper = $('.product-summary-block');
    if (checkoutWrapper.length) {
        checkoutWrapper.find('.view-order-details-wrapper .card').each(function () {
            var numItems = 0;
            var inputs = $(this).find('.quantity-selector__value');
            var text;
            // Initialize a variable to store the count
            // Iterate through the input elements
            inputs.each(function () {
                // Check if the input element is visible
                if ($(this).is(':visible')) {
                    // Add the value to the count
                    numItems += parseInt($(this).val());
                }
            });
            if (parseInt(numItems) > 1) {
                text = checkoutWrapper.data('msg').replace(/#/g, numItems);
            } else {
                text = checkoutWrapper.data('msg-singular').replace(/#/g, numItems);
            }
            $(this).find('.items').text(text);
        })
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml =
        '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        message +
        '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">' + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            lineItem.availability.promiseDates = $('.availability-' + lineItem.UUID).find('.minicart-promise-dates-wrapper').html();
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes font-weight-bold text-success">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages +=
                '<p class="line-item-attributes line-item-instock-date">' + lineItem.availability.inStockDate + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
    if ($(".availability-" + lineItem.UUID + ".minicart-promise-dates-wrapper").length) {
        $('.availability-' + lineItem.UUID).find('.minicart-promise-dates-wrapper').empty().html(lineItem.availability.promiseDates);
    } else {
        var promiseWrapper = "<div class='minicart-promise-dates-wrapper'>demo text </div>";
        $('.availability-' + lineItem.UUID).append(promiseWrapper);
        $('.availability-' + lineItem.UUID).find('.minicart-promise-dates-wrapper').empty().html(lineItem.availability.promiseDates);
    }

    var $pickupInput = $('.omni-form[data-uuid="' + lineItem.UUID + '"]').find('input[data-delivery-type="pickup"]');
    if (lineItem.deliveryMethod && lineItem.deliveryMethod !== $pickupInput.val() && lineItem.shipToStoreDate && lineItem.shipToLocationName) {
        $pickupInput.val(lineItem.deliveryMethod);
        $pickupInput.siblings('label').children('.line-item-store-name').text(lineItem.shipToStoreDate + ' at ' + lineItem.shipToLocationName);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="editProductModal" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            initSlider();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');
    if (actionUrl !== 'warranty') {
        $deleteConfirmBtn.data('pid', productID);
        $deleteConfirmBtn.data('action', actionUrl);
        $deleteConfirmBtn.data('uuid', uuid);
    } else {
        $deleteConfirmBtn.data('pid', productID);
        $deleteConfirmBtn.data('action', actionUrl);
        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('isWarranty', true);
    }

    $productToRemoveSpan.empty().append(productName);
}

/**
 * inits quick view slider
 */
function initSlider() {
    $('.js-primary-images').waitForImages(function () {
        $('.js-qv-slider').slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.js-qv-slider-nav',
            lazyLoad: 'progressive'
        });
        $('.js-qv-slider-nav').slick({
            slidesToShow: 3,
            asNavFor: '.js-qv-slider',
            centerMode: true,
            focusOnSelect: true,
            arrows: false,
            lazyLoad: 'progressive'
        });
    });
}

function updateCartPoolPerks(subTotal, hasWarranty) {
    // Pool perks
    var subTotalWithoutCurrency = Math.floor(subTotal);
    var hasWarrantyProduct = hasWarranty;
    var poolPerksDetails = $('.js-cart-perks-wrapper-details');
    var poolPerksMinTotal = 19;
    var poolPerksRate = 20;

    if (subTotalWithoutCurrency > poolPerksMinTotal) {
        if (hasWarrantyProduct) {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + poolPerksDetails.attr('data-static-reward-currency') + Math.floor(subTotalWithoutCurrency / poolPerksRate) + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shippingandwarranty'))
        } else {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + poolPerksDetails.attr('data-static-reward-currency') + Math.floor(subTotalWithoutCurrency / poolPerksRate) + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shipping'))
        }
    } else {
        if (hasWarrantyProduct) {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + (subTotalWithoutCurrency % poolPerksRate) + ' points' + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shippingandwarranty'))
        } else {
            poolPerksDetails.html('<span class="perks-label">' + poolPerksDetails.attr('data-static-reward-money') + '</span><b>' + (subTotalWithoutCurrency % poolPerksRate) + ' points' + '</b> <br />' + '<div class="relate-perks-info">' + poolPerksDetails.attr('data-static-info-text') + '</div>' + poolPerksDetails.attr('data-static-reward-shipping'))
        }
    }
}



module.exports = function () {
    $('body').on('click', '.checkout-btn', function (e) {
        e.preventDefault();
        if ($.spinner()) {
            $.spinner().start();
        }
        $('body').trigger('checkout:begin');
        var url = $(this).attr("href");
        document.location = url;
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('click', '.remove-warranty-product', function (e) {
        e.preventDefault();
        var actionUrl = $(this).data('name');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    // setTimeout(function(){
    //     $('.cart-error-messaging').fadeIn('fast').delay(5000).fadeOut('slow');
    // }, 5000);

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var $buttonsToDelete = $('.cart-delete-confirmation-btn');
        var isWarranty = !!($(this).data('isWarranty'));
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var triggeredFromBuyNowModal = $(this).hasClass('buynow-remove-product');
        var urlParams = {
            pid: productID,
            uuid: uuid,
            isWarranty: isWarranty
        };

        url = appendToUrl(url, urlParams);

        if ($('.page').data('action') === 'Product-Show' || $('.page').data('action') === 'Search-Show') {
            $('body').addClass('modal-open');
            $('html').addClass('veiled');
            $('body > .modal-backdrop').addClass('fade show');
            $('#itemsLabel').text("");
            $('#totalSectionResult').text("")
        } else {
            $('body > .modal-backdrop').remove();
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            beforeSend: function () {
                $buttonsToDelete.attr('disabled', true);
            },
            success: function (data) {
                $buttonsToDelete.removeAttr('disabled');
                updateCartTotals(data.basket);
                if (data.basket.items.length === 0) {
                    $('.cart')
                        .empty()
                        .append(
                            '<div class="row"> ' +
                            '<div class="col-12 text-center"> ' +
                            '<h1>' +
                            data.basket.resources.emptyCartMsg +
                            '</h1> ' +
                            '</div> ' +
                            '</div>'
                        );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.cart-header-subtotal').empty();
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });

                    if ($('.page').data('action') === 'Product-Show' || $('.page').data('action') === 'Search-Show') {
                        $('body > .modal-backdrop').remove();
                        $('#buyNowModal').hide();
                    }

                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    $('.cart.cart-page').addClass('d-none');
                    $('.container.cart-empty').removeClass('d-none');
                } else {
                    if ($('.page').data('action') === 'Product-Show' || $('.page').data('action') === 'Search-Show') {
                        var isDesktopView = $(window).width();
                        if (data.basket.items.length === 2 && isDesktopView >= 768) {
                            $('.buynow-carousel').slick('unslick')
                        }

                        if (data.basket.items.length === 1 && isDesktopView < 768) {
                            $('.buynow-carousel').slick('unslick')
                        }
                        setTimeout(function () {
                            if (parseInt(data.basket.numItems) > 1) {
                                $('#itemsLabel').text(data.basket.numItems + ' Items');
                            } else {
                                $('#itemsLabel').text(data.basket.numItems + ' Item');
                            }
                            $('#totalSectionResult').text(data.basket.totals.grandTotal)
                        }, 1000)
                    }

                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }

                    var $uuidDiv = $('.uuid-' + uuid);
                    var $omniParent = $uuidDiv.parents('.omni-category');
                    if ($omniParent.length) {
                        var omniData = {
                            parent: $omniParent,
                            uuid: uuid
                        }
                        $('body').trigger('cart:omniRemoval', omniData);
                    } else {
                        $uuidDiv.parents('.slick-slide').remove();
                        $uuidDiv.remove();
                    }
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                    if (data.showApplePayButton) {
                        $('#apple-pay-cart-button').show();
                    } else {
                        $('#apple-pay-cart-button').hide();
                    }

                    var cartSignInTextType = data.basket.hasSorProduct ? 'accublue' : data.basket.hasWarrantyProduct ? 'warranty' : 'normal'
                    var cartSignInText = $('.js-cart-signin__text').attr('data-text-' + cartSignInTextType);
                    $('.js-cart-signin__text').text(cartSignInText);


                    // Pool perks
                    updateCartPoolPerks(data.basket.totals.subTotalWithoutCurrency, data.basket.hasWarrantyProduct);
                }


                $('body').trigger('cart:update');
                $('body').trigger('cart:removal', data);

                if ($('.refill-text-interval').length == 0) {
                    $('body').find('.sor-message-wrapper-global:not(.cart-signin)').remove();
                }

                $.spinner().stop();
            },
            error: function (err) {
                $buttonsToDelete.removeAttr('disabled');
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-keep-confirmation-btn', function (e) {
        $('.js-quantity-value').each(function () {
            if ($(this).val() === '0') {
                $(this).val($(this).attr('value'));
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateWarranty($(this), data);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update');
                $('body').trigger('cart:quantityUpdate', data);

                if (data.resources.bopisError) {
                    $(this).parents('.product-info').find('.bopis-error').removeClass('d-none').html(data.resources.bopisError);
                } else {
                    $(this).parents('.product-info').find('.bopis-error').addClass('d-none');
                }

                if ($('.product-summary-block').length) {
                    var text;
                    if (parseInt(data.numItems) > 1) {
                        text = $('.product-summary-block').data('msg').replace(/#/g, data.numItems);
                    } else {
                        text = $('.product-summary-block').data('msg-singular').replace(/#/g, data.numItems);
                    }
                    $('.view-order-details').find('.items').text(text);
                }

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    function handleQuantityChange(url, $this, quantity, prevQuantity, productID, uuid) {
        var urlParams = {
            pid: productID,
            quantity: quantity,
            prevQuantity: prevQuantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            $.ajax({
                url: url,
                type: 'get',
                context: this,
                dataType: 'json',
                beforeSend: function () {
                    $this.parents('.card').spinner().start();
                },
                success: function (data) {
                    $('.minicart').trigger('count:update', data);
                    $('.mini-cart-slide-drawer__product-summary').empty().append(data.renderedMiniCartTemplate);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateWarranty($this, data);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateAvailability(data, uuid);

                    /*When Qty change Ajax response is over it updates the HTML dom Pickup Radio button as "ShipToStore". 
                    We are setting back to PickUpAtStore*/
                    try {
                        var currentPid = data.items[0].id;
                        var altProductAttribute = $('[data-alt-substitution-pairs]').attr('data-alt-substitution-pairs');
                        //Below logic to execute only for Substitution Product Pairs
                        if (altProductAttribute.indexOf(currentPid) > -1) {
                            $('[data-delivery-type="pickup"]').val("PickUpAtStore")
                        }
                    } catch (e) {
                        //Saftey try/catch and Intentionally not printing any console errors.
                    }

                    validateBasket(data);

                    if ($('.product-summary-block').length) {
                        var text;
                        if (parseInt(data.numItems) > 1) {
                            text = $('.product-summary-block').data('msg').replace(/#/g, data.numItems);
                        } else {
                            text = $('.product-summary-block').data('msg-singular').replace(/#/g, data.numItems);
                        }
                        $('.view-order-details').find('.items').text(text);
                    }

                    // Pool perks
                    updateCartPoolPerks(data.totals.subTotalWithoutCurrency, data.hasWarrantyProduct);
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        }, 500);
    }

    $(document).on('click', '.js-quantity-button', function (event) {
        event.preventDefault();
        var $this = $(this);
        var $productLineItem = $(this).closest('.product-info');
        var isIncrease = $(this).hasClass('js-quantity-more');
        var $selector = $(this).closest('.quantity-selector');
        var $value = $selector.find('.js-quantity-value');
        var quantity = parseInt($selector.data('value'));
        var prevQuantity = parseInt($productLineItem.data('quantity') || $value.attr('value'));
        var maxOrderQuantity = parseInt($selector.data('maxOrderQuantity'));
        var productID = $selector.data('pid');
        var uuid = $selector.data('uuid');
        var url = $selector.data('action');

        if (isIncrease) {
            if (quantity <= maxOrderQuantity) {
                if (quantity < maxOrderQuantity) {
                    quantity++;
                    $(this).siblings('button').removeClass('quantity-selector__button--disabled');
                    $productLineItem.find('.js-quantity-message').addClass('d-none');
                    $productLineItem.find('.js-quantity-more').attr('disabled', false);
                }
                if (quantity === maxOrderQuantity) {
                    $(this).addClass('quantity-selector__button--disabled');
                    $productLineItem.find('.js-quantity-more').attr('disabled', true);
                    if (quantity === 1) return;
                } else {
                    $(this).removeClass('quantity-selector__button--disabled');
                }
                $productLineItem.find('.js-quantity-less').attr('disabled', false);
            }
        } else if (quantity > 1) {
            quantity--;
            $(this).siblings('button').removeClass('quantity-selector__button--disabled');
            if (quantity === 1) {
                $(this).addClass('quantity-selector__button--disabled');
            } else {
                $(this).removeClass('quantity-selector__button--disabled');
            }
            $productLineItem.find('.js-quantity-message').addClass('d-none');
            $productLineItem.find('.js-quantity-more').attr('disabled', false);
        } else if (quantity === 1 || quantity === 0) {
            var removeProductWrapper = $productLineItem.find('.remove-product');
            $productLineItem.find('.js-quantity-message').addClass('d-none');
            $productLineItem.find('.js-quantity-more').attr('disabled', false);
            if (removeProductWrapper && removeProductWrapper.length) {
                removeProductWrapper.click();
                return
            } else {
                $productLineItem.find('.js-quantity-less').attr('disabled', true);
                quantity = 1;
                $value.val(quantity);
            }
        }
        $selector.data('value', quantity);
        $value.text(quantity.toString());
        $value.val(quantity);
        if ($productLineItem.find('.js-line-item-qty')) {
            $($productLineItem.find('.js-line-item-qty')).text(quantity);
        }
        handleQuantityChange(url, $this, quantity, prevQuantity, productID, uuid);

    });

    $(document).on('input', '.js-quantity-value', function (event) {
        event.preventDefault();
        var $this = $(this);
        var $selector = $(this).closest('.quantity-selector');
        var $productLineItem = $(this).closest('.product-info');
        var quantity = parseInt($this.val());
        if (isNaN(quantity) || $this.val() === '') return;
        var prevQuantity = parseInt($productLineItem.find('.quantity-selector').data('value'));
        if (quantity < 0 || (!Number.isInteger(quantity))) {
            $this.val(prevQuantity)
            return;
        }

        var maxOrderQuantity = parseInt($selector.data('maxOrderQuantity'));
        var isIncrease = prevQuantity < quantity;

        var productID = $selector.data('pid');
        var uuid = $selector.data('uuid');
        var url = $selector.data('action');

        if (isIncrease) {
            if (quantity <= maxOrderQuantity) {
                if (quantity < maxOrderQuantity) {
                    $(this).siblings('button').removeClass('quantity-selector__button--disabled');
                    $productLineItem.find('.js-quantity-message').addClass('d-none');
                    $productLineItem.find('.js-quantity-more').attr('disabled', false);
                }
                if (quantity === maxOrderQuantity) {
                    $(this).addClass('quantity-selector__button--disabled');
                    $productLineItem.find('.js-quantity-message').removeClass('d-none');
                    $productLineItem.find('.js-quantity-more').attr('disabled', true);
                    if (quantity === 1) return;
                } else {
                    $(this).removeClass('quantity-selector__button--disabled');
                }
                $productLineItem.find('.js-quantity-less').attr('disabled', false);
            } else {
                $this.val(maxOrderQuantity);
                quantity = maxOrderQuantity;
                $productLineItem.find('.js-quantity-message').removeClass('d-none');
                $productLineItem.find('.js-quantity-more').attr('disabled', true);
            }
        } else if (quantity >= 1) {
            $(this).siblings('button').removeClass('quantity-selector__button--disabled');
            if (quantity === 1) {
                $(this).addClass('quantity-selector__button--disabled');
            } else {
                $(this).removeClass('quantity-selector__button--disabled');
            }
            $productLineItem.find('.js-quantity-message').addClass('d-none');
            $productLineItem.find('.js-quantity-more').attr('disabled', false);
        } else if (quantity === 0) {
            var removeProductWrapper = $productLineItem.find('.remove-product');
            $productLineItem.find('.js-quantity-message').addClass('d-none');
            $productLineItem.find('.js-quantity-more').attr('disabled', false);
            if (removeProductWrapper && removeProductWrapper.length) {
                removeProductWrapper.click();
                return
            } else {
                $productLineItem.find('.js-quantity-less').attr('disabled', true);
                quantity = 1;
                $(this).val(quantity);
            }
        }
        $selector.data('value', quantity);
        $(this).attr('value', quantity);
        if ($productLineItem.find('.js-line-item-qty')) {
            $($productLineItem.find('.js-line-item-qty')).text(quantity);
        }
        if (quantity === prevQuantity) return;

        handleQuantityChange(url, $this, quantity, prevQuantity, productID, uuid);

    });

    $('body').on('storelocator:loaded', function (e, data) {
        var $this = data.elem;
        e.stopPropagation();
        var buyNow = $this.data('buynow');
        var pid = $this.data('pid');
        var uuid = $this.data('uuid');
        var quantity = $('.buy-now-qty').val()

        // Starting store locator from buy now
        if (buyNow) {
            $('.header-results-panel').addClass('buy-now-store-locator');
            $('.secondary-header-styling').css({
                'z-index': 0
            });
            $('nav .header').css({
                'z-index': 'unset'
            });
            $('.buy-now-header-store-locator').removeClass('d-none');
            $('.show-map-div').hide();
            $('#store-location-panel').removeClass('is-invalid');
            $('.product-id').text($('.product-line-item').data('product-id'));
            $('.productNameStoreLocator').text($('.product-line-item').find('.product-name').text());
            $('.dropdown-product-quantity').text(quantity);
        } else {
            $('.header-results-panel').removeClass('buy-now-store-locator');
            $('.secondary-header-styling').removeAttr('style');
            $('nav .header').removeAttr('style');
            $('.buy-now-header-store-locator').addClass('d-none');
        }
        $('.header-banner').hasClass('d-none') ? $('.header-results-panel').addClass('is-banner-hidden') : $('.header-results-panel').removeClass('is-banner-hidden');
        // Finish buy now treatments
        $('.modal-background').css({
            'z-index': 2
        })
        $('body > .modal-backdrop').remove();
        $('body').removeClass('modal-open');
        $('.modal').remove();

        if ($('.header-results-panel .selected-product-info').data('uuid') !== uuid) {
            $('.header-results-panel .selected-product-info').data('pid', pid);
            $('.header-results-panel .selected-product-info').data('uuid', uuid);
            $('.header-results-panel .store-locator').data('referrer', $this.data('referrer'))
            if ($('.header-results-panel .pinned-local-store-container .postal-code').is('*') && $('.dropdown-results .store-result').length > 0) {
                $('.header-store-form .btn-storelocator-search[type="button"]').trigger('click');
            } else if ($('.dropdown-results .store-result').length > 0) {
                if (navigator.permissions && navigator.permissions.query) {
                    navigator.permissions.query({
                        name: 'geolocation'
                    }).then(function (result) {
                        if (result.state !== 'denied' && result.state !== 'blocked' && $('.header-results-panel .store-result').length > 0) {
                            $('.header-store-form .detect-location').trigger('click');
                        }
                    })
                } else if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function () {
                            $('.header-store-form .detect-location').trigger('click');
                        },
                        function (err) {
                            //error
                        }, {
                        timeout: 1000
                    }
                    );
                }
            }
        }
        $('.store-dropdown-toggle').attr('data-toggle', '')
        $('.store-dropdown-toggle').dropdown('toggle');
    });

    // Go back button on buy now store locator
    $('body').on('click', '.btn-go-back-store-locator', function () {
        $('.store-dropdown-toggle').dropdown('hide');
    })

    $('body').on('click', '.qvcheck-stores', function (e) {
        e.stopPropagation();
        $('#quickViewModal').modal('hide');
        $('.store-dropdown-toggle').attr('data-toggle', '')
        $('.store-dropdown-toggle').dropdown('toggle');
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    if (data.removeExtendedMessage) {
                        $('.promo-code-form .form-control').addClass('is-invalid');
                        $('.coupon-error-message').empty().append(data.removeExtendedMessage);
                        setTimeout(function () {
                            location.reload();
                        }, 1000);
                    } else {
                        $('.promo-code-form .form-control').removeClass('is-invalid');
                        $('.coupon-error-message').empty();
                    }
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);

                    // Pool perks
                    updateCartPoolPerks(data.totals.subTotalWithoutCurrency, data.hasWarrantyProduct);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('click', '.cart-page .product-edit .js-spa-cover-edit', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var redirectUrl = $(this).attr('href');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('cart:update');
                $('body').trigger('cart:removal', data);
                window.location.href = redirectUrl;
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') ||
            !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability', dialog).data('ready-to-order', allReady).data('available', allAvailable);

            $('.global-availability .availability-msg', dialog)
                .empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('form[name="sendCart"]').on('submit', function (e) {
        e.preventDefault();
        var action = $('form[name="sendCart"]').attr('action');
        var $form = $('form[name="sendCart"]')
        $.spinner().start();
        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: $form.serialize(),
            success: function (data) {
                $form.addClass('d-none').removeClass('d-flex');
                $('.email-cart-btn').addClass('d-none');
                if (data.success) {
                    $('div.send-cart-msg').text(data.message).addClass('alert-success').removeClass('d-none');
                } else {
                    $('div.send-cart-msg').text(data.message).addClass('alert-danger').removeClass('d-none');
                }
                $.spinner().stop();
            },
            error: function (data) {
                $('div.send-cart-msg').text(data.message).addClass('alert-danger').removeClass('d-none');
                $.spinner().stop();
            }
        });
    })


    $(document).on('change', '.remove-warranty-checkbox', function (e) {
        e.preventDefault();
    });

    $(document).on('change', '.js-product-option-checkbox', function () {
        var $this = $(this);
        var data = {
            pid: $this.attr('data-pid'),
            uuid: $this.attr('data-uuid'),
            optionid: $this.attr('data-optionid'),
            selectedvalueid: $this.attr('data-selectedvalueid'),
            checked: $this.prop('checked')
        };
        $.spinner().start();
        $.ajax({
            url: $this.attr('data-url'),
            method: 'GET',
            dataType: 'json',
            data: data,
            success: function (data) {
                if (!data.result.error) {
                    $('.tax-total').text(data.totalTax);
                    $('.grand-total').text(data.totalGrossPrice);
                    location.reload();
                }
                $.spinner().stop();
            },
            error: function (err) {
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.view-order-details .msg', function (e) {
        e.preventDefault();
        var $wrapper = $(this).closest('.product-summary-block');
        var url = $wrapper.find('.view-order-details').data('action');
        var $spinner = $wrapper.find('.view-order-details').spinner();
        if (!url) {
            $(this).addClass('d-none');
            if ($(this).hasClass('msg-opened')) {
                $wrapper.find('.msg-hidden').removeClass('d-none');
                $wrapper.find('.view-order-details-wrapper').addClass('d-none');
            } else {
                $wrapper.find('.msg-opened').removeClass('d-none');
                $wrapper.find('.view-order-details-wrapper').removeClass('d-none');
            }
            return;
        }
        $spinner.start();
        if ($('.data-checkout-stage') && $('.data-checkout-stage').attr('data-checkout-stage')) {
            url += '?stage=' + $('.data-checkout-stage').attr('data-checkout-stage');
        }
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $spinner.stop();
                $wrapper.html(data);
                require('../checkout/checkout').loadedPromiseDates();
            },
            error: function (err) {
                $spinner.stop();
            }
        });
    });

    function toggleStickyButton(show) {
        if (show) {
            $('.js-checkout-continue-mobile').show();
        } else {
            $('.js-checkout-continue-mobile').hide();
        }
    }

    function observeMobileCheckoutButton() {
        let checkoutButton = document.querySelector('.intersection-checkout');

        function createObserver() {
            let observer;

            let options = {
                root: null,
                rootMargin: "0px 0px -124px 0px",
                threshold: 0.01,
            };

            observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(checkoutButton);
        }

        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {

                    toggleStickyButton(true);

                } else {
                    toggleStickyButton(false);
                }
            });
        }

        createObserver();
    }

    $(document).ready(function () {

        var promiseDateUrl = $('.cart-page').attr('data-promise-url');
        if (promiseDateUrl) {
            $('.omni-form').spinner().start();
            $.when(sthAjax(promiseDateUrl), stsAjax(promiseDateUrl)).done(function (a1, a2) {
                $('.omni-form').spinner().stop();
                $(document).trigger('promiseDates:loaded');
            });
        }

        function sthAjax(promiseDateUrl) {
            return $.ajax({
                url: promiseDateUrl,
                method: 'GET',
                success: function (response) {
                    if (response && response.promiseDates) {
                        $('.omni-form').each(function (idx, elem) {
                            var uuidKey = $(elem).attr('data-uuid');
                            if (response.promiseDates[uuidKey]) {
                                $(elem).find('.estimate-shipping-time-wrapper').addClass('d-none');
                                if (response.promiseDates[uuidKey].renderedPromiseDate) {
                                    $(elem).find('.wrapper-sth-dates').empty().html(response.promiseDates[uuidKey].renderedPromiseDate)
                                } else {
                                    if (response.promiseDates[uuidKey].promiseDateFormat) {
                                        $(elem).find('.wrapper-sth-dates').attr('data-formatteddate', response.promiseDates[uuidKey].promiseDateFormat);
                                    }
                                    $(elem).find('.wrapper-sth-dates').attr('data-rawdate', response.promiseDates[uuidKey].promiseDate).attr('data-postal', response.promiseDates[uuidKey].postalCode);
                                }
                            } else {
                                $(elem).find('.estimate-shipping-time-wrapper').removeClass('d-none');
                            }
                        });
                    }
                }
            });
        }

        function stsAjax(promiseDateUrl) {
            if (promiseDateUrl) {
                return $.ajax({
                    url: promiseDateUrl + '?isSTS=true',
                    method: 'GET',
                    success: function (response) {
                        if (response && response.promiseDates) {
                            $('.omni-form').each(function (idx, elem) {
                                var uuidKey = $(elem).attr('data-uuid');
                                if (response.promiseDates[uuidKey]) {
                                    $(elem).find('.estimate-bopis-time-wrapper:not(.bopis)').addClass('d-none');
                                    if (response.promiseDates[uuidKey].renderedPromiseDate) {
                                        $(elem).find('.wrapper-sts-dates').empty().html(response.promiseDates[uuidKey].renderedPromiseDate);
                                    } else {
                                        if (response.promiseDates[uuidKey].promiseDateFormat) {
                                            $(elem).find('.wrapper-sts-dates').attr('data-formatteddate', response.promiseDates[uuidKey].promiseDateFormat);
                                        }
                                        $(elem).find('.wrapper-sts-dates').attr('data-rawdate', response.promiseDates[uuidKey].promiseDateSTS);
                                    }
                                } else {
                                    $(elem).find('.estimate-bopis-time-wrapper').removeClass('d-none');
                                }
                            });
                        }
                    }
                })
            }
        }

        if (window.innerWidth < 544) {
            observeMobileCheckoutButton();
        }

        $(window).on("resize", function () {
            if (window.innerWidth < 544) {
                observeMobileCheckoutButton();
            }
        });

    });

    $(document).on('click', '.pli-change-delivery-to', function (e) {
        e.preventDefault();
        var perent = $(this).closest('.omni-wrapper')
        perent.find('.delivery-to-pli-form').removeClass('d-none');
        $(this).addClass('d-none');
        perent.find('.pli-delivery-to-section').addClass('d-none');
        perent.find('.pli-change-delivery-to-hide').removeClass('d-none');
    });

    $(document).on('click', '.pli-change-delivery-to-hide', function (e) {
        e.preventDefault();
        var perent = $(this).closest('.omni-wrapper')
        perent.find('.delivery-to-pli-form').addClass('d-none');
        $(this).addClass('d-none');
        perent.find('.pli-delivery-to-section').removeClass('d-none');
        perent.find('.pli-change-delivery-to').removeClass('d-none');
    });

    $('.delivery-to-pli-form').on('submit', function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        var url = $(this).attr('action');
        $(this).find("#deliveryToPostalPli").removeClass('is-invalid');
        $(this).find("#pliDeliverytoError").html('');
        var zipCode = $(this).find('#deliveryToPostalPli').val();
        var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
        if (!isValidZip) {
            $(this).find("#deliveryToPostalPli").addClass('is-invalid');
            $(this).find("#pliDeliverytoError").html('Please add a valid zip code');
            return false;
        }

        var timezone = new Date().toLocaleDateString(undefined, {
            day: '2-digit',
            timeZoneName: 'short'
        }).substring(4);
        if (timezone && timezone != 'undefined') {
            data = data + "&timezone=" + timezone;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            data: data,
            method: 'POST',
            success: function (response) {
                if (response.success) {
                    $(this).find('.delivery-to-code').html($(this).find('#deliveryToPostalPli').val());
                    $(this).find('#deliveryToPostalPli').val('');
                    window.location.reload();
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        })
    });


    $(document).on('click', '.view-selections .view-selections-js', function (e) {
        e.preventDefault();
        $(e.currentTarget).closest('.view-selections').find('.hide-selections-js').removeClass('d-none');
        $(e.currentTarget).closest('.view-selections').find('.selections-content').removeClass('d-none');
        $(this).addClass('d-none');
    });

    $(document).on('click', '.view-selections .hide-selections-js', function (e) {
        e.preventDefault();
        $(e.currentTarget).closest('.view-selections').find('.view-selections-js').removeClass('d-none');
        $(e.currentTarget).closest('.view-selections').find('.selections-content').addClass('d-none');
        $(this).addClass('d-none');
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
